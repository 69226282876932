import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import cookie from "react-cookies";
import ContentLoader from "react-content-loader";
import { apiUrl, unquieID } from "../Settings/Config";
import { GET_STORE_LIST } from "../../actions";
import Cart from "../Cart/Cart";
import cart from "../../common/images/cart.svg";
import wishlist from "../../common/images/wishlist.svg";
import ypin from "../../common/images/map-pin-yellow.svg";
import barrow from "../../common/images/back-arrow.svg";

import searchg from "../../common/images/search-green.svg";
import kasa1 from "../../common/images/kasa.png";
import food from "../../common/images/food.png";
import restaurantclose from "../../common/images/restaurant-close.svg";
import $ from "jquery";
class Allrestaurants extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      storeList: [],
      locationName:
        cookie.load("locationName") !== "" &&
        typeof cookie.load("locationName") !== undefined &&
        typeof cookie.load("locationName") !== "undefined"
          ? cookie.load("locationName")
          : "",
      locationImage:
        cookie.load("locationImage") !== "" &&
        typeof cookie.load("locationImage") !== undefined &&
        typeof cookie.load("locationImage") !== "undefined"
          ? cookie.load("locationImage")
          : "",
      storeDisplay: "",
      keywords: "",
      totalfavourite: "0",
      cartdetails: [],
      tagList: [],
    };
  }
  componentDidMount() {
    if (
      cookie.load("locationID") !== "" &&
      typeof cookie.load("locationID") !== undefined &&
      typeof cookie.load("locationID") !== "undefined"
    ) {
      this.props.getStoreList(
        "&dellocation=" +
          cookie.load("locationID") +
          "&latitude=" +
          cookie.load("deliveryaddresslat") +
          "&longitude=" +
          cookie.load("deliveryaddresslong")
      );
    }
    this.handleChange = this.handleChange.bind(this);
    this.loadTotalFav();
    this.loadTag();
  }
  componentWillReceiveProps(PropsDt) {
    if (this.state.storeList !== PropsDt.storeList) {
      this.setState(
        { storeList: PropsDt.storeList, loading: false },
        function () {
          this.displayStore();
        }
      );
    }
    if (this.state.cartdetails !== PropsDt.cartdetails) {
      this.setState({ cartdetails: PropsDt.cartdetails });
    }
  }
  loadTag() {
    axios.get(apiUrl + "store/tagList?unquieid=" + unquieID).then((res) => {
      if (res.data.status === "ok") {
        this.setState({ tagList: res.data.result }, function () {
          if (
            cookie.load("locationID") !== "" &&
            typeof cookie.load("locationID") !== undefined &&
            typeof cookie.load("locationID") !== "undefined"
          ) {
            var latitude =
              cookie.load("deliveryaddresslat") !== "" &&
              typeof cookie.load("deliveryaddresslat") !== undefined &&
              typeof cookie.load("deliveryaddresslat") !== "undefined"
                ? cookie.load("deliveryaddresslat")
                : "";
            var longitude =
              cookie.load("deliveryaddresslong") !== "" &&
              typeof cookie.load("deliveryaddresslong") !== undefined &&
              typeof cookie.load("deliveryaddresslong") !== "undefined"
                ? cookie.load("deliveryaddresslong")
                : "";
            this.props.getStoreList(
              "&dellocation=" +
                cookie.load("locationID") +
                "&latitude=" +
                latitude +
                "&longitude=" +
                longitude
            );
          }
        });
      }
    });
  }
  loadTotalFav() {
    axios
      .get(
        apiUrl +
          "store/getTotalFavourite?unquieid=" +
          unquieID +
          "&customerID=" +
          cookie.load("customerID")
      )
      .then((res) => {
        this.setState({ totalfavourite: res.data.totalfavourite });
      });
  }
  handleChange(e) {
    this.setState({ keywords: e.target.value }, function () {
      this.displayStore();
    });
  }

  loadStoreTag(storeTag) {
    if (this.state.tagList.length > 0) {
      var storeTag = storeTag.split(",");
      var tagList = [];
      this.state.tagList.map((item) => {
        if (storeTag.indexOf(item.value) >= 0) {
          tagList.push(item.label);
        }
      });
      if (tagList.length > 0) {
        return tagList.join(", ");
      }
    }
  }

  displayStore() {
    var storeDisplay = "";
    if (this.state.storeList.length > 0) {
      storeDisplay = this.state.storeList.map((item, index) => {
        var checking = "Yes";
        if (this.state.keywords !== "") {
          if (
            item.storeName
              .toLowerCase()
              .indexOf(this.state.keywords.toLowerCase()) >= 0
          ) {
            checking = "Yes";
          } else {
            checking = "No";
          }
        }
        if (checking === "Yes") {
          return (
            <li key={index}>
              <Link to={"/restaurant/" + item.storeSlug} className="ot-parent">
                <div
									style={{
										height: '80px',
										width: '80px',
									}}
									className={`${
										item?.storeOpen === '0'
											? 'storeclosed inner-store-close'
											: ''
									}`}>
									<img
										style={{
											height: '100%',
											width: '100%',
											objectFit: 'cover',
											objectPosition: 'center',
										}}
										src={
											item.storeImage !== '' && item.storeImage !== null
												? item.storeImage
												: food
										}
										alt={item.storeName}
									/>
								</div>
                <div className="ot-info">
                  {item.storeOpen === "0" && (
                    <div className="storeclosed-msg">
                      <img src={restaurantclose} alt="Restaurant is closed" />
                      <span>Restaurant is closed</span>
                    </div>
                  )}

                  <div
                    className={`${
                      item?.storeOpen === "0"
                        ? "storeclosed inner-store-close"
                        : ""
                    }`}
                  >
                    <h3 className="ot-title">{item.storeName}</h3>
                    <div className="km-rating">
                      <strong>
                        {item.distance !== ""
                          ? parseFloat(item.distance).toFixed("2")
                          : "0"}{" "}
                        km
                      </strong>
                      {item.Rating !== "" && item.Rating !== null && (
                        <span>
                          {item.Rating} ({item.totalRating})
                        </span>
                      )}
                    </div>
                    <div className="op-time">{item.storeTimeInfo}</div>
                    {item.tagID !== "" &&
                      item.tagID !== null &&
                      this.state.tagList.length > 0 && (
                        <div className="ot-keyword">
                          {this.loadStoreTag(item.tagID)}
                        </div>
                      )}
                    {item.offerInfo !== "" && item.offerInfo !== null && (
                      <div className="ot-offers">{item.offerInfo}</div>
                    )}
                  </div>
                </div>
              </Link>
            </li>
          );
        }
      });
    }
    this.setState({ storeDisplay: storeDisplay });
  }
  render() {
    return (
      <div className="main-div">
        <Cart />
        <div className="header-action header-action-center">
          <div className="container">
            <div className="hac-lhs">
              <Link to={"/"} className="arrow-back">
                <img src={barrow} />
              </Link>
            </div>
            <div className="ha-middle">Near me</div>
            <div className="ha-rhs">
              <ul>
                <li>
                  <Link to={"/favourites"}>
                    <img src={wishlist} />
                    {parseInt(this.state.totalfavourite) > 0 && (
                      <span className="cart-count">
                        {this.state.totalfavourite}
                      </span>
                    )}
                  </Link>
                </li>
                <li>
                  <Link to={"/cart"}>
                    <img src={cart} />
                    {Object.keys(this.state.cartdetails).length > 0 && (
                      <span className="wish-count">
                        {this.state.cartdetails.totalItem}+
                      </span>
                    )}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="content-body rel">
          <div className="container">
            {this.state.locationName !== "" && (
              <div className="eats-area">
                <div className="ea-lhs">
                  <span>Eats Area</span>
                  <p>
                    <img src={ypin} /> {this.state.locationName}
                  </p>
                </div>
                <div className="ea-rhs">
                  {this.state.locationImage !== "" && (
                    <img
                      src={this.state.locationImage}
                      alt={this.state.locationName}
                      title={this.state.locationName}
                    />
                  )}
                </div>
              </div>
            )}
            <div className="main-search">
              <div className="ms-inner">
                <img src={searchg} alt="voucher" />
                <input
                  type="text"
                  className="input-fill"
                  placeholder="Find your Restaurants"
                  onChange={this.handleChange}
                />
              </div>
            </div>

            <div className="outlet-listing">
              <div className="outlet-main-list">
                <ul>
                  {" "}
                  {this.state.loading === true
                    ? Array(1, 2, 3).map((item) => {
                        return (
                          <li key={item}>
                            <ContentLoader viewBox="0 0 380 70">
                              <rect
                                x="0"
                                y="0"
                                rx="5"
                                ry="5"
                                width="70"
                                height="70"
                              />
                              <rect
                                x="80"
                                y="17"
                                rx="4"
                                ry="4"
                                width="300"
                                height="13"
                              />
                              <rect
                                x="80"
                                y="40"
                                rx="3"
                                ry="3"
                                width="250"
                                height="10"
                              />
                            </ContentLoader>
                          </li>
                        );
                      })
                    : this.state.storeDisplay}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var storelistArr = Array();
  if (Object.keys(state.storelist).length > 0) {
    if (state.storelist[0].status === "ok") {
      storelistArr = state.storelist[0].result;
    }
  }
  var cartdetailsArr = Array();
  if (Object.keys(state.cartdetails).length > 0) {
    if (state.cartdetails[0].status === "ok") {
      cartdetailsArr = state.cartdetails[0].result;
    }
  }
  return {
    storeList: storelistArr,
    cartdetails: cartdetailsArr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getStoreList: (params) => {
      dispatch({ type: GET_STORE_LIST, params });
    },
  };
};
export default connect(
  mapStateTopProps,
  mapDispatchToProps
)(withRouter(Allrestaurants));
