import { useEffect, useRef, useState } from 'react';
import { apiUrl, maxLengthNotes } from '../Settings/Config';
import { getCookie, isEmptyData } from '../Helpers/SettingHelper';
import axios from 'axios';

const Notes = ({ item_id = '', notes = '', callback = () => {} }) => {
	const [note, setNote] = useState('');
	const debounceTimeout = useRef(null);

	useEffect(() => {
		if (!isEmptyData(notes)) {
			setNote(notes);
		}
	}, [notes]);

	const debounce = (func, delay) => {
		console.log('debounce');
		return (...args) => {
			if (debounceTimeout.current) {
				clearTimeout(debounceTimeout.current);
			}
			debounceTimeout.current = setTimeout(() => {
				func(...args);
			}, delay);
		};
	};

	const handleUpdateNotes = async (note) => {
		console.log('handleUpdateNotes');
		try {
      const data = new FormData();
      data.append('item_id', item_id);
      data.append('notes', note);

			await axios
				.post(`${apiUrl}cart/update-note-cart-item`, data, {
          headers: {
            Authorization: getCookie('clientAccessToken'),
            'Content-Type': 'multipart/form-data',
          }
        })
				.then((res) => {
					console.log('res: ', res);
          callback();
				})
				.catch((err) => {
					console.log('err: ', err);
				});
		} catch (error) {
			console.log('error: ', error);
		}
	};

	const debouncedUpdate = debounce((newNote) => {
		console.log('debouncedUpdate');
		handleUpdateNotes(newNote);
	}, 1000);

	const handleChange = (note) => {
		const newNote = note;
		setNote(newNote);
		debouncedUpdate(newNote);
	};

	return (
		<div style={{ width: '100%', paddingTop: '10px' }}>
			<div className='text-box'>
				<h3>
					Notes <span>(Optional)</span>
				</h3>
				<textarea
					name='notes'
					id='notes'
					maxLength={maxLengthNotes}
					className='no-margin'
					style={{ marginBottom: '0', height: 'auto', scrollbarWidth: 'none' }}
					value={note || ''}
					rows={4}
					onChange={(e) => handleChange(e.target.value)}></textarea>
			</div>
		</div>
	);
};

export default Notes;
