import React, { Component } from "react";
import { Link } from "react-router-dom";
import ContentLoader from "react-content-loader";
import axios from "axios";
import cookie from "react-cookies";
import { format } from "date-fns";
import { apiUrl, unquieID } from "../Settings/Config";
import {
  showPrice,
  stripslashes,
  convertTimestamptoTime,
  capitalizeFirstLetter,
} from "../Settings/SettingHelper";
import bike from "../../common/images/bike.svg";
import barrow from "../../common/images/back-arrow.svg";
import Chken from "../../common/images/Chicken.png";
import clock from "../../common/images/scheduleclock.svg";
import grabex from "../../common/images/grabexpress.svg";

import printer from "../../common/images/printer.svg";

import cpyicn from "../../common/images/copy-icon.svg";
import chaticn from "../../common/images/chat-icon.svg";
import telephoneicn from "../../common/images/telephone-icon.svg";
import kasa1 from "../../common/images/kasa.png";

class Historydetails extends Component {
  constructor(props) {
    super(props);
    var orderType = "";
    if (
      this.props.match.params.orderType !== "" &&
      typeof this.props.match.params.orderType !== undefined &&
      typeof this.props.match.params.orderType !== "undefined"
    ) {
      orderType = this.props.match.params.orderType;
    } else {
      orderType = "on-process";
    }
    var orderNumber = "";
    if (
      this.props.match.params.orderNumber !== "" &&
      typeof this.props.match.params.orderNumber !== undefined &&
      typeof this.props.match.params.orderNumber !== "undefined"
    ) {
      orderNumber = this.props.match.params.orderNumber;
    }
    this.state = {
      loading: true,
      orderType: orderType,
      orderNumber: orderNumber,
      orderDetails: "",
    };
    if (orderNumber === "") {
      this.props.history.push("/history");
    }
  }
  componentDidMount() {
    this.loadOrderDetails();
  }
  loadOrderDetails() {
    axios
      .get(
        apiUrl +
          "orders/orderDetails?unquieid=" +
          unquieID +
          "&customerID=" +
          cookie.load("customerID") +
          "&orderNumber=" +
          this.state.orderNumber
      )
      .then((res) => {
        if (res.data.status === "ok") {
          this.setState({
            orderDetails: res.data.result[0],
            loading: false,
          });
        } else {
          this.props.history.push("/history");
        }
      });
  }
  render() {
    return (
      <div className="main-div transaction-details">
        <div className="header-action header-action-center">
          <div className="container">
            <div className="hac-lhs">
              <Link
                to={"/history/" + this.state.orderType}
                className="arrow-back"
              >
                <img src={barrow} />
              </Link>
            </div>
            <div className="ha-middle">History Details</div>
            <div className="ha-rhs ha-rhs-link"></div>
          </div>
        </div>
        {this.state.loading === true ? (
          <div className="content-body rel history-detail">
            <div className="container">
              <div className="voucher-list on-process-list">
                <ul>
                  <li>
                    <ContentLoader viewBox="0 0 380 70">
                      <rect x="0" y="0" rx="5" ry="5" width="70" height="70" />
                      <rect
                        x="80"
                        y="17"
                        rx="4"
                        ry="4"
                        width="300"
                        height="13"
                      />
                      <rect
                        x="80"
                        y="40"
                        rx="3"
                        ry="3"
                        width="250"
                        height="10"
                      />
                    </ContentLoader>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        ) : (
          <div className="content-body rel history-detail">
            {this.state.orderDetails !== "" && (
              <div className="container">
                {(this.state.orderDetails.orderStatus === "1" ||
                  this.state.orderDetails.orderStatus === "2" ||
                  this.state.orderDetails.orderStatus === "3") && (
                  <div className="status-recipt sr-process">On Process</div>
                )}
                {this.state.orderDetails.orderStatus === "4" && (
                  <div className="status-recipt sr-completed">Completed</div>
                )}
                {this.state.orderDetails.orderStatus === "5" && (
                  <div className="status-recipt sr-cancel">Canceled</div>
                )}
                {this.state.orderDetails.orderStatus === "5" && (
                  <div className="cancled-txt">
                    Unfortunately, we are unable to process your order at this
                    time due to cancellation by the merchant.
                  </div>
                )}

                {this.state.orderDetails.delivery_order_details !== "" &&
                  this.state.orderDetails.delivery_order_details !== null &&
                  this.state.orderDetails.delivery_order_details.data !== "" &&
                  typeof this.state.orderDetails.delivery_order_details.data !==
                    undefined &&
                  typeof this.state.orderDetails.delivery_order_details.data !==
                    "undefined" && (
                    <div className="delivery-status-box">
                      <h2 className="td-header">Delivery Status</h2>
                      {/* <div className="order-loc-box-two">
                    <ul>
                      <li>
                        <a href="#">
                          <img src={bike} />
                          <strong>Vehicle Type</strong>
                          <span>Choose vehicle type</span>
                        </a>
                      </li>
                    </ul>
                  </div> */}
                      <div className="dstatus-list">
                        {this.state.orderDetails.delivery_order_details.data !==
                          null && (
                          <ul>
                            {this.state.orderDetails.delivery_order_details.data
                              .track.tracks.length > 0 && (
                              <li className="active">
                                <div className="ds-indent">
                                  <div className="dsi-lhs">
                                    <strong>
                                      {
                                        this.state.orderDetails
                                          .delivery_order_details.data.track
                                          .tracks[0].status
                                      }
                                    </strong>
                                    <span>
                                      {
                                        this.state.orderDetails
                                          .delivery_order_details.data.track
                                          .tracks[0].message
                                      }
                                    </span>
                                  </div>
                                  <div className="dsi-rhs">
                                    <strong>
                                      {convertTimestamptoTime(
                                        this.state.orderDetails
                                          .delivery_order_details.data.track
                                          .tracks[0].createdAt
                                      )}{" "}
                                      WIB
                                    </strong>
                                    <span>
                                      {" "}
                                      {format(
                                        new Date(
                                          this.state.orderDetails.order_date
                                        ),
                                        "dd MMM y"
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </li>
                            )}
                          </ul>
                        )}
                      </div>
                    </div>
                  )}

                <div className="dd-order-detail">
                  <h2 className="td-header">Delivery Details</h2>
                  <div className="order-loc-box-one">
                    <ul>
                      {this.state.orderDetails.orderItems.length > 0 && (
                        <li>
                          <a href={void 0}>
                            <span></span>
                            <h2>
                              {this.state.orderDetails.orderItems[0].storeName}
                            </h2>
                            <p title={this.state.orderDetails.pickupLocation}>
                              {
                                this.state.orderDetails.orderItems[0]
                                  .outlet_phone
                              }
                              <br />
                              {this.state.orderDetails.pickupLocation}
                            </p>
                          </a>
                        </li>
                      )}
                      <li>
                        <a href={void 0}>
                          <span></span>
                          <h2>{this.state.orderDetails.custmoerName}</h2>
                          <p title={this.state.orderDetails.address}>
                            {this.state.orderDetails.phone}
                            <br />
                            {this.state.orderDetails.address}
                          </p>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="detail-transaction order-details-trans">
                  <div className="dt-header">
                    <h2>Detail Transaction</h2>
                  </div>
                  <div className="dt-body">
                    <span>
                      {format(
                        new Date(this.state.orderDetails.order_date),
                        "dd MMM y"
                      )}
                    </span>
                    <span>
                      Order Number : {this.state.orderDetails.orderNumber}{" "}
                      <a href={void 0} className="ordr-cpy">
                        <img src={cpyicn} alt="Copy" />
                      </a>
                    </span>
                    <span>
                      Payment Reference Number :{" "}
                      {this.state.orderDetails.payment_order_id}
                    </span>
                    <em className="grn-txt">
                      Delivery -{" "}
                      {this.state.orderDetails.deliveryData
                        ?.deliveryPartnerName !== "" &&
                        this.state.orderDetails.deliveryData
                          ?.deliveryPartnerName}{" "}
                      {this.state.orderDetails.deliveryData?.serviceType !==
                        "" &&
                        this.state.orderDetails.deliveryData?.serviceType}{" "}
                      {this.state.orderDetails.deliveryData?.vehicleType !==
                        "" && this.state.orderDetails.deliveryData?.vehicleType}
                    </em>
                    <div className="dt-body-img">
                      <img src={printer} />
                    </div>
                  </div>
                </div>
                <div className="driver-info">
                  {this.state.orderDetails.order_driver_ref_id !== "" &&
                    this.state.orderDetails.order_driver_ref_id !== null && (
                      <div className="dinfo-lhs">
                        <div className="driver-av-img">
                          <img src={Chken} />
                        </div>
                        <div className="driver-txt">
                          <h4>
                            {this.state.orderDetails.order_driver_name} (Driver)
                          </h4>
                          <p>{this.state.orderDetails.order_driver_ref_id}</p>
                        </div>
                      </div>
                    )}
                  <div className="dinfo-rhs">
                    <a
                      href={
                        this.state.orderDetails.order_driver_phone !== "" &&
                        this.state.orderDetails.order_driver_phone !== null
                          ? "tel:" + this.state.orderDetails.order_driver_phone
                          : void 0
                      }
                    >
                      {" "}
                      <img src={telephoneicn} />{" "}
                    </a>
                    <a
                      href={
                        this.state.orderDetails.order_driver_phone !== "" &&
                        this.state.orderDetails.order_driver_phone !== null
                          ? "https://wa.me/" +
                            this.state.orderDetails.order_driver_phone
                          : void 0
                      }
                    >
                      {" "}
                      <img src={chaticn} />{" "}
                    </a>
                  </div>
                </div>
                <div className="recipient-info">
                  <div className="ri-img">
                    <img src={Chken} />
                  </div>
                  <div className="ri-txt">
                    <h4>{this.state.orderDetails.custmoerName} (Recipient)</h4>
                    <span>{this.state.orderDetails.phone} </span>
                    <p>
                      {this.state.orderDetails.address}{" "}
                      {this.state.orderDetails.addressRemarks !== "" &&
                        this.state.orderDetails.addressRemarks !== null &&
                        "(" + this.state.orderDetails.addressRemarks + ")"}
                    </p>
                  </div>
                </div>
                {this.state.orderDetails.orderItems.length > 0 &&
                  this.state.orderDetails.orderItems.map((store, storeInde) => {
                    var totalItem = 0;
                    if (store.items.length > 0) {
                      store.items.map((item) => {
                        totalItem += parseInt(item.itemQuantity);
                      });
                    }
                    return (
                      <div className="product-order" key={storeInde}>
                        <div className="outlet-product">
                          <figure>
                            <img src={kasa1} />
                          </figure>
                          <div className="outlet-pro-name">
                            <p>{store.storeName}</p>
                            <span>{totalItem} Menu</span>
                          </div>
                        </div>
                        {store.items.length > 0 && (
                          <ul>
                            {store.items.map((item, index) => {
                              return (
                                <li key={index}>
                                  <div className="ot-top">
                                    <div className="otp-img">
                                      x{item.itemQuantity}
                                    </div>
                                    <div className="otp-desc">
                                      <div className="otp-history-desc">
                                        <h3 className="ot-title">
                                          {item.itemName}
                                        </h3>
                                        {item.comboset.length > 0 && (
                                          <div className="otp-desc-comob">
                                            {item.comboset.map(
                                              (comboItem, comobIndex) => {
                                                return (
                                                  <p key={comobIndex}>
                                                    <strong>
                                                      {comboItem.comboSetname}
                                                    </strong>
                                                    <br />
                                                    {comboItem.productDetails
                                                      .length > 0 &&
                                                      comboItem.productDetails.map(
                                                        (
                                                          comobPro,
                                                          comboProIndex
                                                        ) => {
                                                          return (
                                                            <span
                                                              key={
                                                                comboProIndex
                                                              }
                                                            >
                                                              {comboProIndex !==
                                                                0 && " + "}
                                                              {stripslashes(
                                                                comobPro.productName
                                                              )}
                                                              {parseInt(
                                                                comobPro.quantity
                                                              ) > 0 && (
                                                                <>
                                                                  <b>
                                                                    {" "}
                                                                    x
                                                                    {
                                                                      comobPro.quantity
                                                                    }
                                                                  </b>
                                                                </>
                                                              )}
                                                              {parseFloat(
                                                                comobPro.productPrice
                                                              ) > 0 && (
                                                                <>
                                                                  (+
                                                                  {showPrice(
                                                                    comobPro.productPrice
                                                                  )}
                                                                  )
                                                                </>
                                                              )}
                                                            </span>
                                                          );
                                                        }
                                                      )}
                                                  </p>
                                                );
                                              }
                                            )}
                                          </div>
                                        )}
                                      </div>
                                      <span>{showPrice(item.itemPrice)}</span>
                                    </div>
                                  </div>
                                  {item.itemNote !== "" &&
                                    item.itemNote !== null && (
                                      <div className="ot-note">
                                        <span>Note :</span>
                                        <p>{item.itemNote}</p>
                                      </div>
                                    )}
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </div>
                    );
                  })}

                <div className="transaction-summary">
                  <h4>Summary Payment</h4>
                  <ul>
                    <li>
                      <span>
                        Subtotal ({this.state.orderDetails.orderTotalItems}{" "}
                        Menu)
                      </span>
                      <strong>
                        {showPrice(this.state.orderDetails.subTotal)}
                      </strong>
                    </li>
                    {(parseInt(this.state.orderDetails.deliveryCharge) > 0) && (
                      <li>
                        <span>Delivery Fee</span>
                        <strong>
                          {showPrice(
                            parseFloat(this.state.orderDetails.deliveryCharge) +
                              parseFloat(this.state.orderDetails?.deliveryFee)
                          )}
                        </strong>
                      </li>
                    )}
                    {(parseInt(this.state.orderDetails?.packagingFee) > 0) && (
                      <li>
                        <span>Packaging Fee</span>
                        <strong>
                          {showPrice(this.state.orderDetails?.packagingFee)}
                        </strong>
                      </li>
                    )}
                    {(parseInt(this.state.orderDetails?.platformFee) > 0) && (
                      <li>
                        <span>Platform Fee</span>
                        <strong>
                          {showPrice(this.state.orderDetails?.platformFee)}
                        </strong>
                      </li>
                    )}

                    {this.state.orderDetails.discount.length > 0 &&
                      this.state.orderDetails.discount.map((item, index) => {
                        return (
                          <li className="ts-discount" key={index}>
                            <span>Discount ({item.promoCode})</span>
                            <strong>-{showPrice(item.promoAmount)}</strong>
                          </li>
                        );
                      })}
                    {this.state.orderDetails.voucher.length > 0 &&
                      this.state.orderDetails.voucher.map((item, index) => {
                        return (
                          <li className="ts-discount" key={index}>
                            <span>Voucher {item.name}</span>
                            <strong>-{showPrice(item.amount)}</strong>
                          </li>
                        );
                      })}
                    <li className="ts-total">
                      <span>Total Transaction</span>
                      <strong>
                        {showPrice(this.state.orderDetails.totalAmount)}
                      </strong>
                    </li>
                  </ul>
                </div>
                {this.state.orderDetails.delivery_order_details !== "" &&
                  this.state.orderDetails.delivery_order_details !== null &&
                  this.state.orderDetails.delivery_order_details.data !== "" &&
                  typeof this.state.orderDetails.delivery_order_details.data !==
                    undefined &&
                  typeof this.state.orderDetails.delivery_order_details.data !==
                    "undefined" && (
                    <div className="service-track">
                      <h2 className="td-header">Service Details</h2>
                      <div className="order-loc-box-two">
                        <ul>
                          <li>
                            <a href={void 0}>
                              <img src={clock} />
                              <strong>
                                {
                                  this.state.orderDetails.delivery_order_details
                                    .data.serviceType
                                }
                              </strong>
                              <span>Delivery within 1-2 hours</span>
                            </a>
                          </li>

                          <li>
                            <a href={void 0}>
                              <img
                                src={
                                  this.state.orderDetails.delivery_order_details
                                    .data.partner.iconUrl
                                }
                              />
                              <strong>
                                {
                                  this.state.orderDetails.delivery_order_details
                                    .data.partner.name
                                }
                              </strong>
                              <span>
                                {
                                  this.state.orderDetails.delivery_order_details
                                    .data.vehicleTypeCategory
                                }{" "}
                                | up to 5 kg
                              </span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default Historydetails;
