import React, { Component, createRef } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import cookie from "react-cookies";
import ContentLoader from "react-content-loader";

import { GET_STORE_LIST, GET_VOUCHER_LIST } from "../../actions";
import {
  apiUrl,
  unquieID,
  deliveryId,
  baseUrl,
  privateImageUrl,
  publicImageUrl,
  listStatusLookingForDriver,
} from "../Settings/Config";
import {
  encodeValue,
  showPrice,
  startRefreshToken,
  stopRefreshToken,
  stripslashes,
} from "../Settings/SettingHelper";
import Cart from "../Cart/Cart";

import OwlCarousel from "react-owl-carousel2";
import "../../common/css/owl.carousel.css";
import cart from "../../common/images/cart.svg";
import list from "../../common/images/list.svg";
import wishlist from "../../common/images/wishlist.svg";
import mpin from "../../common/images/map-pin-green.svg";
import mpinlight from "../../common/images/map-pin-green-light.svg";
import ypin from "../../common/images/map-pin-yellow.svg";
import barrow from "../../common/images/back-arrow.svg";
import searchg from "../../common/images/search-green.svg";
import food from "../../common/images/food.png";
import cha from "../../common/images/cha-time.png";
import heartline from "../../common/images/heart.svg";
import gchat from "../../common/images/chat-icon.svg";
import gbike from "../../common/images/green-bike.svg";
import tele from "../../common/images/telephone-icon.svg";
import welcomeuv from "../../common/images/welcome-uv.svg";
import restaurantclose from "../../common/images/restaurant-close.svg";
import { fetchAndCompressImage, saveToIndexedDB } from "../Helpers/imageUtils";
import { isEmptyData, setCookie, getCookie } from "../Helpers/SettingHelper";
import $ from "jquery";

if (process.env.REACT_APP_NODE_ENV === "production") {
  console.log = function no_console() {};
} else {
  const originalConsoleError = console.error;
  console.error = function (message) {
    if (!message.includes("Warning: findDOMNode is deprecated")) {
      originalConsoleError.apply(console, arguments);
    }
  };
}

var Parser = require("html-react-parser");
var mbanner = {
  items: 1,
  loop: true,
  dots: true,
  nav: false,
  margin: 15,
  stagePadding: 40,
  responsive: {
    0: {
      items: 1,
      stagePadding: 20,
    },
    480: {
      items: 1,
      margin: 15,
      stagePadding: 40,
    },
  },
};
var recmdbanner = {
  items: 2,
  loop: true,
  dots: false,
  nav: false,
  margin: 13,
  stagePadding: 40,
  responsive: {
    0: {
      items: 1,
      stagePadding: 40,
    },
    380: {
      items: 2,
      stagePadding: 30,
    },
  },
};
var qs = require("qs");
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tagList: [],
      bannerList: [],
      storeList: [],
      storeDisplay: "",
      loading: true,
      locationName: getCookie("locationName"),
      locationImage: getCookie("locationImage"),
      cartdetails: [],
      voucherlist: "",
      totalfavourite: "0",
      onGoingOrder: [],
      displyonGoingOrder: [],
      delZoneID: getCookie("delZoneID"),
      recomentList: [],
      inititalLoad: false,
      recomentDisplay: "",
      displaySearch: "",
      searchProductList: [],
      searchKeywords: "",
      filterTagID: [],
      bannerRef: createRef(),
      recomendRef: createRef(),
    };
    this.intervalId = null;
  }
  componentDidMount() {
    console.log("componentDidMount");
    $("body").removeClass("bodybgdark");
    this.displyaSearchresult();
    this.getSearchProducts();
    const searchParams = new URLSearchParams(document.location.search);
    var token = searchParams.get("token");
    var refresh_token = searchParams.get("refresh_token");
    if (!isEmptyData(token) && !isEmptyData(refresh_token)) {
      setCookie("token", token);
      setCookie("refresh_token", refresh_token);
      this.loadUserDetails(token);
    }
    if (isEmptyData(this.state.delZoneID)) {
      this.setState({ inititalLoad: true });
      $("body").addClass("welcomeuv-bg");
    }

    this.loadTag();
    this.loadBanner();
    this.loadTotalFav();
    this.loadOngoinOrders();
    /* this.props.getVoucherList(); */
    this.loadRecomentPro();
    this.intervalId = startRefreshToken();
  }

  componentWillUnmount() {
    console.log('componentWillUnmount');
    stopRefreshToken(this.intervalId);
  }

  componentWillReceiveProps(PropsDt) {
    if (this.state.storeList !== PropsDt.storeList) {
      this.setState({ storeList: PropsDt.storeList }, function () {
        this.displayStore();
      });
    }
    if (this.state.cartdetails !== PropsDt.cartdetails) {
      this.setState({ cartdetails: PropsDt.cartdetails });
    }
  }
  getSearchProducts() {
    var latitude = getCookie("deliveryaddresslat");
    var longitude = getCookie("deliveryaddresslong");
    axios
      .get(
        apiUrl +
          "catalogs/searchproducts?unquieid=" +
          unquieID +
          "&locationID=" +
          cookie.load("locationID") +
          "&latitude=" +
          latitude +
          "&longitude=" +
          longitude +
          "&avilability=" +
          deliveryId
      )
      .then((res) => {
        if (res.data.status === "ok") {
          this.setState({ searchProductList: res.data.result });
        }
      });
  }
  async loadUserDetails(token) {
    console.log("loadUserDetails");
    var postObject = {
      unquieid: unquieID,
      token: token,
    };

    await axios
      .post(apiUrl + "customers/login", qs.stringify(postObject))
      .then(async (res) => {
        if (res.data.status === "ok") {
          const {
            firstName,
            lastName,
            email,
            primary_phone,
            customerUVID,
            customerID,
            address: customerAddress,
            pickupAddress,
          } = res.data.result;
          const {
            address,
            country,
            address_id,
            city,
            district,
            state,
            postal_code,
            latitude,
            longitude,
          } = customerAddress || {};

          setCookie("firstName", firstName);
          setCookie("lastName", lastName);
          setCookie("email", email);
          setCookie("primary_phone", primary_phone);
          setCookie("customerUVID", encodeValue(customerUVID));
          setCookie("customerID", encodeValue(customerID));

          setCookie("deliveryaddress", address || "");
          setCookie("deliverycountry", country || "");
          setCookie("deliverystate", state || "");
          setCookie("deliverypostalCode", postal_code || "");
          setCookie("deliverycity", city || "");
          setCookie("deliverydistrict", district || "");
          setCookie("deliveryaddresslat", latitude || "");
          setCookie("deliveryaddresslong", longitude || "");
          setCookie("addressID", address_id || "");

          !isEmptyData(pickupAddress) && (await this.selectLocation(pickupAddress));

          this.setState({ inititalLoad: true }, () => {
            $("body").addClass("welcomeuv-bg");
            this.props.history.push("/");
          });
        }
      });
  }

  async selectLocation(location) {
    console.log("selectLocation");
    var latitude = getCookie("deliveryaddresslat");
    var longitude = getCookie("deliveryaddresslong");
    await axios
      .get(
        apiUrl +
          "store/findDeliveryZone?unquieid=" +
          unquieID +
          "&locationID=" +
          location.locationID +
          "&latitude=" +
          latitude +
          "&longitude=" +
          longitude +
          "&avilability=" +
          deliveryId
      )
      .then((res) => {
        if (res.data.status === "ok") {
          const { delZoneID, delZoneName } = res.data.result;

          setCookie("delZoneID", encodeValue(delZoneID));
          setCookie("delZoneName", delZoneName);
          setCookie("locationID", encodeValue(location.locationID));
          setCookie("locationName", location.locationName);
          // setCookie("locationAddress", location.address);
          setCookie("locationImage", location.image);
          window.location.href = baseUrl;
        }
      });
  }

  loadTotalFav() {
    axios
      .get(
        apiUrl +
          "store/getTotalFavourite?unquieid=" +
          unquieID +
          "&customerID=" +
          cookie.load("customerID")
      )
      .then((res) => {
        this.setState({ totalfavourite: res.data.totalfavourite });
      });
  }
  loadTag() {
    axios.get(apiUrl + "store/tagList?unquieid=" + unquieID).then((res) => {
      if (res.data.status === "ok") {
        this.setState({ tagList: res.data.result }, function () {
          var locationID = getCookie("locationID");
          if (!isEmptyData(locationID)) {
            var latitude = getCookie("deliveryaddresslat");
            var longitude = getCookie("deliveryaddresslong");
            this.props.getStoreList(
              "&dellocation=" +
                cookie.load("locationID") +
                "&latitude=" +
                latitude +
                "&longitude=" +
                longitude
            );
          }
        });
      }
    });
  }
  loadBanner() {
    axios.get(apiUrl + "banner/listBanner?unquieid=" + unquieID).then((res) => {
      if (res.data.status === "ok") {
        this.setState({ bannerList: res.data.result });
      }
    });
  }
  handleImageLoad = async (storeID, storeImage, key) => {
    this.setState((prevState) => ({
      imageLoadStatus: {
        ...prevState.imageLoadStatus,
        [storeID]: "loaded",
      },
    }));

    const imageUrl = storeImage.replace(publicImageUrl, privateImageUrl);

    try {
      // Use the fetchAndCompressImage function to fetch, compress, and store the image
      const dataUrl = await fetchAndCompressImage(key, storeImage, imageUrl);

      if (dataUrl) {
        // Save the image data URL to IndexedDB using the imported function
        saveToIndexedDB(key, storeImage, dataUrl);
      }
    } catch (error) {
      console.error("Error in handleImageLoad:", error);
    }
  };
  displayStore() {
    var storeDisplay = "";
    if (this.state.storeList.length > 0) {
      storeDisplay = this.state.storeList.map((item, index) => {
        var splitTag =
          item.tagID !== "" && item.tagID !== null ? item.tagID.split(",") : [];
        var validTag = this.validateTag(splitTag);
        if (validTag === "Yes") {
          return (
            <li key={index}>
              <Link to={"/restaurant/" + item.storeSlug} className="ot-parent">
                <div
                  style={{
                    height: "80px",
                    width: "80px",
                  }}
                  className={`${
                    item?.storeOpen === "0"
                      ? "storeclosed inner-store-close"
                      : ""
                  }`}
                >
                  <img
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                    src={
                      item.storeImage !== "" && item.storeImage !== null
                        ? item.storeImage
                        : food
                    }
                    alt={item.storeName}
                    onLoad={() => {
                      this.handleImageLoad(
                        item.storeID,
                        item.storeImage,
                        "store"
                      );
                    }}
                  />
                </div>
                <div className="ot-info">
                  {item.storeOpen === "0" && (
                    <div className="storeclosed-msg">
                      <img src={restaurantclose} alt="Restaurant is closed" />
                      <span>Restaurant is closed</span>
                    </div>
                  )}
                  <div
                    className={`${
                      item?.storeOpen === "0"
                        ? "storeclosed inner-store-close"
                        : ""
                    }`}
                  >
                    <h3 className="ot-title">{item.storeName}</h3>
                    <div className="km-rating">
                      <strong>
                        {item.distance !== ""
                          ? parseFloat(item.distance).toFixed("2")
                          : "0"}{" "}
                        km
                      </strong>
                      {item.Rating !== "" && item.Rating !== null && (
                        <span>
                          {item.Rating} ({item.totalRating})
                        </span>
                      )}
                    </div>
                    <div className="op-time">{item.storeTimeInfo}</div>
                    {item.tagID !== "" &&
                      item.tagID !== null &&
                      this.state.tagList.length > 0 && (
                        <div className="ot-keyword">
                          {this.loadStoreTag(item.tagID)}
                        </div>
                      )}
                    {item.offerInfo !== "" && item.offerInfo !== null && (
                      <div className="ot-offers">{item.offerInfo}</div>
                    )}
                  </div>
                </div>
              </Link>
            </li>
          );
        }
        return null;
      });
    }
    this.setState({ storeDisplay: storeDisplay, loading: false });
  }
  validateTag(storeTag) {
    var validateTags = "No";
    if (this.state.filterTagID.length > 0) {
      this.state.filterTagID.forEach((item) => {
        if (storeTag.indexOf(item) >= 0) {
          validateTags = "Yes";
        }
      });
    } else {
      validateTags = "Yes";
    }
    return validateTags;
  }

  loadStoreTag(storeTag) {
    if (this.state.tagList.length > 0) {
      storeTag = storeTag.split(",");
      var tagList = [];
      this.state.tagList.forEach((item) => {
        if (storeTag.indexOf(item) >= 0) {
          tagList.push(item.label);
        }
      });
      if (tagList.length > 0) {
        return tagList.join(", ");
      }
    }
  }
  loadOngoinOrders() {
    axios
      .get(
        apiUrl +
          "orders/orderList?unquieid=" +
          unquieID +
          "&customerID=" +
          cookie.load("customerID") +
          "&orderType=Ongoing"
      )
      .then((res) => {
        if (res.data.status === "ok") {
          this.setState({ onGoingOrder: res.data.result }, function () {
            this.displayOngoingOrder();
          });
        }
      });
  }
  displayOngoingOrder() {
    var displyonGoingOrder = [];
    if (this.state.onGoingOrder.length > 0) {
      displyonGoingOrder = this.state.onGoingOrder.map((item, index) => {
        const statusDriver = item?.delivery_order_details ? item?.delivery_order_details?.status : null;
        return (
          <div className="og-box mb10" key={index}>
            <div className="ogbox-img">
              <img src={gbike} alt="Bike" />
            </div>
            <div className="ogbox-txt">
              <span>{isEmptyData(statusDriver) ? "Waiting Merchant Confirmation" : listStatusLookingForDriver.includes(statusDriver) ? "Looking for Driver" : statusDriver}</span>
              <h3>
                {item.store.map((storeItem, storeIndex) => {
                  return (
                    <span key={storeIndex}>
                      {storeIndex !== 0 ? ", " : ""}
                      {storeItem.name}
                    </span>
                  );
                })}
              </h3>
              <div className="ogbox-btn home-orders">
                <a
                  href={
                    item.order_driver_phone !== "" &&
                    item.order_driver_phone !== null
                      ? "tel:" + item.order_driver_phone
                      : '#'
                  }
                  className={`button btn-ghost ${(isEmptyData(item.order_driver_phone) || listStatusLookingForDriver.includes(statusDriver)) && 'hidden'}`}
                >
                  <img src={tele} alt="Telephone" /> Call Driver
                </a>
                <a
                  href={
                    item.order_driver_phone !== "" &&
                    item.order_driver_phone !== null
                      ? `https://wa.me/${item.order_driver_phone}`
                      : "#"
                  }
                  className={`button btn-normal home-chat-order ${(isEmptyData(item.order_driver_phone) || listStatusLookingForDriver.includes(statusDriver)) && 'hidden'}`}
                >
                  <img src={gchat} alt="Chat" /> Chat Driver
                </a>

                {item.delivery_order_details !== "" &&
                item.delivery_order_details?.trackingUrl ? (
                  <Link
                    to={
                      "/track-order/" +
                      (this.state.orderType ?? "on-process") +
                      "/" +
                      item.orderNumber
                    }
                    className="button btn-ghost home-track-order"
                  >
                    <img src={mpinlight} alt="track" />
                    Track Order
                  </Link>
                ) : (
                  <Link
                    to={
                      "/history/" +
                      (this.state.orderType ?? "on-process") +
                      "/" +
                      item.orderNumber
                    }
                    className="button btn-ghost home-track-order"
                  >
                    <img src={mpinlight} alt="track" /> View Details
                  </Link>
                )}
              </div>
            </div>
          </div>
        );
      });
    }
    this.setState({ displyonGoingOrder: displyonGoingOrder });
  }
  setAddress() {
    $("body").removeClass("welcomeuv-bg").css("overflow", "auto");
    this.props.history.push("/delivery-state");
  }
  loadRecomentPro() {
    var latitude = getCookie("deliveryaddresslat");
    var longitude = getCookie("deliveryaddresslong");
    if (!isEmptyData(latitude) && isEmptyData(longitude)) {
      axios
        .get(
          apiUrl +
            "catalogs/recomentproducts?unquieid=" +
            unquieID +
            "&type=recommendation" +
            "&latitude=" +
            latitude +
            "&longitude=" +
            longitude +
            "&locationID=" +
            cookie.load("locationID")
        )
        .then((res) => {
          if (res.data.status === "ok") {
            this.setState({ recomentList: res.data.result }, function () {
              this.displyRecoment();
            });
          }
        });
    }
  }
  displyRecoment() {
    var recomentDisplay = "";
    if (this.state.recomentList.length > 0) {
      recomentDisplay = this.state.recomentList.map((item, index) => {
        return (
          <div className="re-parent" key={index}>
            <a to={"#"} className="like">
              {" "}
              <img alt={heartline} src={"heartline"} />{" "}
            </a>
            <Link
              to={
                "/restaurant/" +
                item.outletSlug +
                "/" +
                item.catSlug +
                "/" +
                item.product_slug
              }
              className="main-link"
            >
              <div className="re-mainimg">
                <img
                  alt="product_thumbnail"
                  src={
                    item.product_thumbnail !== "" ? item.product_thumbnail : cha
                  }
                  onLoad={() => {
                    this.handleImageLoad(
                      item.product_id,
                      item.product_thumbnail,
                      "recommendation"
                    );
                  }}
                />
                {item.tagInfo !== "" && (
                  <div className="discount-badge">
                    <div className="discount-badge-inner">
                      <span>UP TO</span>
                      <div className="dark-red">{Parser(item.tagInfo)}</div>
                    </div>
                  </div>
                )}
              </div>
              <div className="re-main-desc">
                <h4>
                  {item.product_alias !== "" && item.product_alias !== null
                    ? stripslashes(item.product_alias)
                    : stripslashes(item.product_name)}
                  <br /> {stripslashes(item.outletName)}
                </h4>
                <div className="km-rating">
                  <strong>
                    {item.distance !== ""
                      ? parseFloat(item.distance).toFixed("2")
                      : "0"}{" "}
                    km
                  </strong>
                  {/*<span>4.8 (1,298)</span>*/}
                </div>
              </div>
            </Link>
          </div>
        );
      });
    }

    this.setState({ recomentDisplay: recomentDisplay });
  }
  changeEatsArea() {
    this.props.history.push("/eatsarea");
  }
  changeDeliveryArea() {
    this.props.history.push("/delivery-state");
  }
  searchPro(e) {
    this.setState({ searchKeywords: e.target.value }, () => {
      this.displyaSearchresult();
    });
  }
  displyaSearchresult() {
    var itemDisplay = "";
    var totalSearchPro = 0;
    if (this.state.searchProductList.length > 0) {
      itemDisplay = this.state.searchProductList.map((item, index) => {
        var proName =
          item.product_alias !== "" && item.product_alias !== null
            ? item.product_alias
            : item.product_name;
        if (
          proName
            .toLocaleLowerCase()
            .indexOf(this.state.searchKeywords.toLocaleLowerCase()) >= 0 ||
          item.outletName
            .toLocaleLowerCase()
            .indexOf(this.state.searchKeywords.toLocaleLowerCase()) >= 0
        ) {
          totalSearchPro++;
          return (
            <li key={index}>
              <div className="search-pro-name">
                <Link
                  to={
                    "/restaurant/" +
                    item.outletSlug +
                    "/" +
                    item.catSlug +
                    "/" +
                    item.product_slug
                  }
                >
                  {proName}
                </Link>
                <Link to={"/restaurant/" + item.outletSlug}>
                  <div>{item.outletName}</div>
                </Link>
              </div>
              <div className="pro-price">
                {showPrice(item.product_price)}
                {item.product_cost !== "" &&
                  item.product_cost !== null &&
                  parseFloat(item.product_cost) > 0 && (
                    <div className="special-price">
                      {showPrice(item.product_cost)}
                    </div>
                  )}
              </div>
            </li>
          );
        }
        return null;
      });
    }
    var searhcResult = "";
    if (this.state.searchKeywords !== "") {
      searhcResult = (
        <ul className="food-search-result">
          {totalSearchPro > 0 ? (
            itemDisplay
          ) : (
            <li>
              <div className="search-pro-name">No Record Found</div>
            </li>
          )}
        </ul>
      );
    }
    this.setState({ displaySearch: searhcResult });
  }
  applyTag(tagID) {
    var filterTagID = this.state.filterTagID;
    if (filterTagID.indexOf(tagID) >= 0) {
      const index = filterTagID.indexOf(tagID);
      if (index > -1) {
        filterTagID.splice(index, 1);
      }
    } else {
      filterTagID.push(tagID);
    }
    this.setState({ filterTagID: filterTagID }, () => {
      this.displayStore();
    });
  }
  render() {
    return (
      <div className="main-div">
        <Cart />
        <div className="header-action">
          <div className="container">
            <div
              className="ha-lhs"
              onClick={this.changeDeliveryArea.bind(this)}
            >
              <Link to={"/delivery-state"} className="arrow-back">
                <img alt="barrow" src={barrow} />
              </Link>
              <span>Your Location</span>
              <p title={cookie.load("deliveryaddress")}>
                <img alt="mpin" src={mpin} /> {cookie.load("deliveryaddress")}
              </p>
            </div>
            <div className="ha-rhs">
              <ul>
                <li>
                  <Link to={"/history/on-process"}>
                    <img alt="list" src={list} />
                  </Link>
                </li>
                <li>
                  <Link to={"/favourites"}>
                    <img alt="wishlist" src={wishlist} />
                    {parseInt(this.state.totalfavourite) > 0 && (
                      <span className="cart-count">
                        {this.state.totalfavourite}
                      </span>
                    )}
                  </Link>
                </li>
                <li>
                  <Link to={"/cart"}>
                    <img alt="cart" src={cart} />
                    {Object.keys(this.state.cartdetails).length > 0 && (
                      <span className="wish-count">
                        {this.state.cartdetails.totalItem}+
                      </span>
                    )}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="content-body rel">
          <div className="container">
            {this.state.bannerList.length > 0 && (
              <div className="banner">
                <OwlCarousel ref={this.state.bannerRef} options={mbanner}>
                  {this.state.bannerList.map((item, index) => {
                    if (
                      item.banner_image !== "" &&
                      item.banner_image !== null
                    ) {
                      return (
                        <div key={index}>
                          <a
                            href={
                              item.banner_link !== ""
                                ? item.banner_link
                                : void 0
                            }
                            target={
                              item.banner_link !== "" ? "_blank" : "_self"
                            }
                            rel={item.banner_link !== "" ? "noreferrer" : undefined}
                          >
                            <img
                              alt="banner_image"
                              src={item.banner_image}
                              onLoad={() => {
                                this.handleImageLoad(
                                  item.banner_id,
                                  item.banner_image,
                                  "banner"
                                );
                              }}
                            />
                          </a>
                        </div>
                      );
                    }
                    return null;
                  })}
                </OwlCarousel>
              </div>
            )}
            {this.state.displyonGoingOrder !== "" && (
              <div className="on-going-home">
                <h2>Ongoing Order ({this.state.onGoingOrder.length})</h2>
                {this.state.displyonGoingOrder}
              </div>
            )}
            <div className="main-search">
              <div className="ms-inner">
                <img src={searchg} alt="voucher" />
                <input
                  type="text"
                  className="input-fill"
                  placeholder="Find your food"
                  onChange={this.searchPro.bind(this)}
                />
              </div>
              {this.state.displaySearch}
            </div>

            {this.state.locationName !== "" && (
              <div
                className="eats-area"
                onClick={this.changeEatsArea.bind(this)}
              >
                <div className="ea-lhs">
                  <span>Eats Area</span>
                  <p>
                    <img alt="ypin" src={ypin} /> {this.state.locationName || ""}
                  </p>
                </div>
                <div className="ea-rhs">
                  {this.state.locationImage !== "" && (
                    <img
                      src={this.state.locationImage}
                      alt={this.state.locationName}
                      title={this.state.locationName}
                    />
                  )}
                </div>
              </div>
            )}
            <div className="pp-tags">
              <ul>
                {this.state.tagList.length > 0 &&
                  this.state.tagList.map((item, index) => {
                    return (
                      <li
                        key={index}
                        className={
                          this.state.filterTagID.indexOf(item.value) >= 0
                            ? "active"
                            : ""
                        }
                      >
                        <a
                          href={void 0}
                          onClick={this.applyTag.bind(this, item.value)}
                        >
                          {item.label}
                        </a>
                      </li>
                    );
                  })}
              </ul>
            </div>

            <div className="outlet-listing">
              <div className="outlet-listing-header">
                <h4>Near Me</h4>
                <Link to={"/restaurants"}>See more</Link>
              </div>
              <div className="outlet-main-list">
                <ul>
                  {this.state.loading === true
                    ? [1, 2, 3].map((item) => {
                        return (
                          <li key={item}>
                            <ContentLoader viewBox="0 0 380 70">
                              <rect
                                x="0"
                                y="0"
                                rx="5"
                                ry="5"
                                width="70"
                                height="70"
                              />
                              <rect
                                x="80"
                                y="17"
                                rx="4"
                                ry="4"
                                width="300"
                                height="13"
                              />
                              <rect
                                x="80"
                                y="40"
                                rx="3"
                                ry="3"
                                width="250"
                                height="10"
                              />
                            </ContentLoader>
                          </li>
                        );
                      })
                    : this.state.storeDisplay}
                </ul>
              </div>
            </div>
            {this.state.recomentDisplay !== "" && (
              <div className="recommand-slider">
                <h2>Recommendation</h2>
                <OwlCarousel ref={this.state.recomendRef} options={recmdbanner}>
                  {this.state.recomentDisplay}
                </OwlCarousel>
              </div>
            )}
          </div>
        </div>
        {this.state.delZoneID === "" && this.state.inititalLoad === true && (
          <div className="welcomeuv textcenter">
            <div className="sry-no service-no">
              <img alt="welcomeuv" src={welcomeuv} />
              <h2>Welcome to UV Delivery</h2>
              <p>Please, set your address or set your eats area</p>
              <a
                href={void 0}
                onClick={this.setAddress.bind(this)}
                className="button button-full"
              >
                Set Address
              </a>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var storelistArr = [];
  if (Object.keys(state.storelist).length > 0) {
    if (state.storelist[0].status === "ok") {
      storelistArr = state.storelist[0].result;
    }
  }
  var cartdetailsArr = [];
  if (Object.keys(state.cartdetails).length > 0) {
    if (state.cartdetails[0].status === "ok") {
      cartdetailsArr = state.cartdetails[0].result;
    }
  }
  var voucherListArr = [];
  if (Object.keys(state.voucherlist).length > 0) {
    voucherListArr = state.voucherlist[0];
  }
  return {
    storeList: storelistArr,
    cartdetails: cartdetailsArr,
    voucherlist: voucherListArr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getStoreList: (params) => {
      dispatch({ type: GET_STORE_LIST, params });
    },
    getVoucherList: () => {
      dispatch({ type: GET_VOUCHER_LIST });
    },
  };
};
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(Home));
