import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import cookie from "react-cookies";
import ContentLoader from "react-content-loader";

import { IonFooter } from "@ionic/react";
import "@ionic/react/css/core.css";

import { apiUrl, unquieID, deliveryId } from "../Settings/Config";
import {
  encodeValue,
  showLoader,
  hideLoader,
  stripslashes,
} from "../Settings/SettingHelper";

import cart from "../../common/images/cart.svg";
import wishlist from "../../common/images/wishlist.svg";
import barrow from "../../common/images/back-arrow.svg";
import searchg from "../../common/images/search-green.svg";
import yet from "../../common/images/not-yet.svg";
import notyet from "../../common/images/not-yet.svg";
import $ from "jquery";
import { setCookie } from "../Helpers/SettingHelper";

class Eatsarea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locationList: [],
      displyLocation: "",
      keywords: "",
      loading: true,
      openFilterSheet: false,
    };
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    this.loadLocation();
  }

  loadLocation() {
    var latitude =
      cookie.load("deliveryaddresslat") !== "" &&
      typeof cookie.load("deliveryaddresslat") !== undefined &&
      typeof cookie.load("deliveryaddresslat") !== "undefined"
        ? cookie.load("deliveryaddresslat")
        : "";
    var longitude =
      cookie.load("deliveryaddresslong") !== "" &&
      typeof cookie.load("deliveryaddresslong") !== undefined &&
      typeof cookie.load("deliveryaddresslong") !== "undefined"
        ? cookie.load("deliveryaddresslong")
        : "";
    axios
      .get(
        apiUrl +
          "store/listDelLocation?unquieid=" +
          unquieID +
          "&latitude=" +
          latitude +
          "&longitude=" +
          longitude
      )
      .then((res) => {
        if (res.data.status === "ok") {
          this.setState({ locationList: res.data.result }, function () {
            this.displyLocation();
          });
        }
      });
  }
  displyLocation() {
    var displyLocation = "";
    var available = 0;
    if (this.state.locationList.length > 0) {
      displyLocation = this.state.locationList.map((item, index) => {
        var error = 0;
        if (this.state.keywords !== "") {
          if (
            item.locationName
              .toLowerCase()
              .indexOf(this.state.keywords.toLowerCase()) < 0
          ) {
            error++;
          }
        }
        if (error === 0) {
          available++;
          return (
            <li
              key={index}
              id={"loc_" + item.locationID}
              onClick={this.selectLocation.bind(this, item)}
            >
              <div className="ea-desc">
                <h3 className="ot-title">{item.locationName}</h3>
                <div className="km-rating">
                  {item.distance !== "" && (
                    <strong>{parseFloat(item.distance).toFixed(2)} km</strong>
                  )}
                  <em>{stripslashes(item.address)}</em>
                </div>
              </div>
              {item.image !== "" && item.image !== null && (
                <div className="ea-img">
                  <img src={item.image} alt={item.address} />
                </div>
              )}
            </li>
          );
        }
      });
    }
    if (available === 0) {
      displyLocation = "";
    }
    this.setState({ displyLocation: displyLocation, loading: false });
  }
  handleChange(e) {
    this.setState({ keywords: e.target.value }, function () {
      this.displyLocation();
    });
  }
  selectLocation(location) {
    showLoader("loc_" + location.locationID);
    var latitude =
      cookie.load("deliveryaddresslat") !== "" &&
      typeof cookie.load("deliveryaddresslat") !== undefined &&
      typeof cookie.load("deliveryaddresslat") !== "undefined"
        ? cookie.load("deliveryaddresslat")
        : "";
    var longitude =
      cookie.load("deliveryaddresslong") !== "" &&
      typeof cookie.load("deliveryaddresslong") !== undefined &&
      typeof cookie.load("deliveryaddresslong") !== "undefined"
        ? cookie.load("deliveryaddresslong")
        : "";
    axios
      .get(
        apiUrl +
          "store/findDeliveryZone?unquieid=" +
          unquieID +
          "&locationID=" +
          location.locationID +
          "&latitude=" +
          latitude +
          "&longitude=" +
          longitude +
          "&avilability=" +
          deliveryId
      )
      .then((res) => {
        hideLoader("loc_" + location.locationID);
        console.log('location: ', location);
        console.log('res.data: ', res.data);
        if (res.data.status === "ok") {
          setCookie("delZoneID", encodeValue(res.data.result.delZoneId || res.data.result.delZoneID));
          setCookie("delZoneName", res.data.result.delZoneName);
          setCookie("locationID", encodeValue(location.locationID));
          setCookie("locationName", location.locationName);
          setCookie("locationImage", location.image);
          this.props.history.push("/");
        } else {
          this.setState({ openFilterSheet: true }, () => {
            $("body").addClass("bodybgdark");
          });
        }
      });
  }
  render() {
    return (
      <div className="main-div">
        <div className="header-action header-action-center">
          <div className="container">
            <div className="hac-lhs">
              <Link to={"/delivery-location"} className="arrow-back">
                <img src={barrow} />
              </Link>
            </div>
            <div className="ha-middle">Eats Area</div>
            <div className="ha-rhs">
              <ul>
                <li>
                  <a href="#">
                    <img src={wishlist} />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img src={cart} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="content-body rel">
          <div className="container">
            <div className="main-search">
              <div className="ms-inner">
                <img src={searchg} alt="voucher" />
                <input
                  type="text"
                  className="input-fill"
                  placeholder="Find your location"
                  value={this.state.keywords}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            {this.state.loading === true ? (
              <div className="eats-list">
                <ul>
                  {Array(1, 2, 3).map((item) => {
                    return (
                      <li key={item}>
                        <ContentLoader viewBox="0 0 380 70" rtl={true}>
                          <rect
                            x="0"
                            y="0"
                            rx="5"
                            ry="5"
                            width="70"
                            height="70"
                          />
                          <rect
                            x="80"
                            y="17"
                            rx="4"
                            ry="4"
                            width="300"
                            height="13"
                          />
                          <rect
                            x="80"
                            y="40"
                            rx="3"
                            ry="3"
                            width="250"
                            height="10"
                          />
                        </ContentLoader>
                      </li>
                    );
                  })}
                </ul>
              </div>
            ) : (
              <>
                {this.state.displyLocation !== "" ? (
                  <div className="eats-list">
                    <ul>{this.state.displyLocation}</ul>
                  </div>
                ) : (
                  <div className="no-add-yet textcenter">
                    <img src={yet} />
                    <h2>
                      You haven't created a delivery
                      <br /> address yet
                    </h2>
                    <p>Please create a delivery address first</p>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        {this.state.openFilterSheet === true && (
          <footer className="footer-main">
            <IonFooter collapse="fade">
              <div className="textcenter">
                <div className="sry-no service-no">
                  <img src={notyet} />
                  <h2>Service Not Available</h2>
                  <p>
                    Sorry the delivery address exceeds maximum radius for
                    delivery. Please change to nearest address or select another
                    merchant
                  </p>
                  <a
                    href={void 0}
                    onClick={() => {
                      $("body").removeClass("bodybgdark");
                      this.setState({ openFilterSheet: false });
                    }}
                    className="button button-full"
                  >
                    Change Location
                  </a>
                </div>
              </div>
            </IonFooter>
          </footer>
        )}
      </div>
    );
  }
}

export default Eatsarea;
