/* eslint-disable */
import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ContentLoader from 'react-content-loader';
import axios from 'axios';

import { IonFooter } from '@ionic/react';
import '@ionic/react/css/core.css';
import cookie from 'react-cookies';
import plusi from '../../common/images/plus.svg';
import minusi from '../../common/images/minus.svg';
import closeicon from '../../common/images/close-icon.svg';
import foodImg from '../../common/images/food.png';
import {
	stripslashes,
	showPrice,
	encodeValue,
	showBtnLoader,
	hideBtnLoader,
	showAlert,
	showLoader,
	hideLoader,
	showPriceDirect,
} from '../Settings/SettingHelper';
import { apiUrl, deliveryId, privateImageUrl, publicImageUrl, unquieID } from '../Settings/Config';
import ProductDetail from './ProductDetail';
import Cart from '../Cart/Cart';
import { fetchAndCompressImage, saveToIndexedDB, displayImageFromIndexedDB } from '../Helpers/imageUtils';
import Notes from '../Notes';

var Parser = require('html-react-parser');
var qs = require('qs');
class ProductList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			productList: [],
			productID: '',
			productDetails: '',
			openFilterSheet: false,
			openExistItem: false,
			cartdetails: {},
			trigerCart: false,
			openExistCartItem: false,
			selectedProduct: '',
			selectedProducttotalPrice: 0,
			selectedProductName: '',
			selectedproductPrice: 0,
			displayExistCartItem: '',
			loadAnotherPro: false,
			incdecPro: '',
			compoinput_value: 1,
			storeList: [],
			openStoreAlert: false,
			productSlug: '',
			openFilterSheetOrder: false,
			totalCartItem: 0,
			imageLoadStatus: {},
			categorySlug: '',
		};
	}
	componentDidMount() {
		$('body').removeClass('bodybgdark');
	}
	componentWillReceiveProps(PropsDt) {
		if (this.state.productList !== PropsDt.product.productList) {
			this.setState({
				productList: PropsDt.product.productList,
			});
		}
		if (this.state.productSlug !== PropsDt.product.productSlug) {
			this.setState({ productSlug: PropsDt.product.productSlug });
		}

		if (this.state.cartdetails !== PropsDt.cartdetails) {
			this.setState({ cartdetails: PropsDt.cartdetails }, function () {
				this.loadExistCartItem();
				if (PropsDt.cartdetails !== '') {
					if (Object.keys(PropsDt.cartdetails).length > 0) {
						var totalItem = 0;
						if (
							PropsDt.cartdetails?.store !== '' &&
							PropsDt.cartdetails?.store !== null
						) {
							if (Object.keys(PropsDt.cartdetails?.store).length > 0) {
								PropsDt.cartdetails?.store.map((item) => {
									if (item.item.length > 0) {
										item.item.map((citem) => {
											totalItem += parseInt(citem.itemQuantity);
										});
									}
								});
							}
						}
					}
					if (totalItem > 0) {
						this.setState({
							openFilterSheetOrder: true,
							totalCartItem: totalItem,
						});
					} else {
						this.setState({
							openFilterSheetOrder: false,
							totalCartItem: 0,
						});
					}
				}

				if (this.state.productID !== '') {
					hideBtnLoader('pro_' + this.state.productID);
					this.setState({ productID: '' });
				}
			});
		}
		this.setState({ categorySlug: PropsDt.categorySlug });
	}
	proQtyAction(Item, Quantity, actionFlg) {
		var checking_Item = this.checkingItem(Item.product_id);
		var allowProceed = 'Yes';
		if (checking_Item > 0 && Item.product_type === '2') {
			allowProceed = 'No';
			this.setState({ openExistItem: true, selectedProduct: Item }, function () {
				$('body').addClass('bodybgdark');
				this.loadExistCartItem();
			});
		}
		if (allowProceed === 'Yes') {
			var updQty = 0;
			if (actionFlg === 'inc') {
				updQty = parseInt(Quantity) + 1;
			} else if (actionFlg === 'dec') {
				updQty = parseInt(Quantity) - 1;
			}
			this.setState({ productID: Item.product_id }, function () {
				showBtnLoader('pro_' + Item.product_id);
				this.addToCartSimpleProduct(Item, updQty, 'update');
			});
		}
	}
	addtoCart(item) {
    console.log('addtoCart: ', item);
		if (this.props.storeOpen === '0') {
			return showAlert('error', "store is closed");
		}

    const storeList = this.state.cartdetails?.storeList || {};
    const storeID = this.props.product.storeID;

		if (Object.keys(storeList).length > 0 && !Object.values(storeList).includes(storeID)) {
      return this.setState({ openStoreAlert: true }, () => {
        $('body').addClass('bodybgdark');
      });
		}

		if (item.product_type === '2') {
			this.loadProductDetail(item);
			this.setState({ openFilterSheet: true });
		} else {
			this.addToCartSimpleProduct(item, 1, 'add');
			showBtnLoader('pro_' + item.product_id);
		}
	}
	async addToCartSimpleProduct(Item, Quantity, type) {
    console.log('addToCartSimpleProduct');
		this.setState({ productID: Item.product_id });
		var postObject = {
			unquieid: unquieID,
			availabilityID: deliveryId,
			availabilityName: 'Delivery',
			productType: 'Simple',
			locationID: cookie.load('locationID'),
			shopID: encodeValue(this.props.product.storeID),
			customerID: cookie.load('customerID'),
			productID: Item.product_id,
			quantity: Quantity,
			type: type,
		};

		await axios.post(apiUrl + 'cart/createCart', qs.stringify(postObject)).then((res) => {
			if (res.data.status === 'ok') {
				this.setState({ trigerCart: true });
				showAlert('success', res.data.message);
			} else if (res.data.status === 'error') {
				showAlert('error', res.data.message);
			}
		});
	}
	async loadProductDetail(product) {
    console.log('loadProductDetail');
		await axios
			.get(
				apiUrl +
					'catalogs/productdetails?unquieid=' +
					unquieID +
					'&productSlug=' +
					product.product_slug,
			)
			.then((res) => {
				if (res.data.status === 'ok') {
					this.setState(
						{ productDetails: res.data.result, openExistItem: false },
						function () {
							$('body').addClass('bodybgdark');
							if (this.state.loadAnotherPro === true) {
								showBtnLoader('add_another_item');
								this.setState({ openFilterSheet: true, loadAnotherPro: false });
							}
						},
					);
				}
			});
	}
	openfilter() {
		this.setState({ openFilterSheet: true });
	}
	sateValChange = (field, value) => {
		if (field === 'openFilterSheet' && value === false) {
			this.setState(
				{
					openFilterSheetOrder: true,
					openFilterSheetOrder: true,
					productDetails: '',
				},
				() => {
					$('body').removeClass('bodybgdark');
				},
			);
		}
		this.setState({ [field]: value });
	};
	checkingItem(productID) {
		var qty = 0;
		var cartdetails = this.state.cartdetails;
		if (Object.keys(cartdetails).length > 0) {
			if (cartdetails.store.length > 0) {
				cartdetails.store.map((store) => {
					if (store.item.length > 0) {
						store.item.map((ProItem) => {
							if (ProItem.productID === productID) {
								qty += parseInt(ProItem.itemQuantity);
							}
						});
					}
				});
			}
		}
		return qty;
	}

	loadExistCartItem() {
		var displayExistCartItem = '';
		var totalPrice = 0;
		var selectedProductName = '';
		if (Object.keys(this.state.cartdetails).length > 0) {
			if (this.state.cartdetails.store.length > 0) {
				this.state.cartdetails.store.map((store) => {
					if (store.item.length > 0) {
						displayExistCartItem = store.item.map((ProItem, proIndex) => {
							totalPrice += parseFloat(ProItem.itemTotalPrice);
							if (ProItem.productID === this.state.selectedProduct.product_id) {
								selectedProductName = ProItem.itemName;
								return (
									<div className='ed-pro-main' key={proIndex}>
										<div className='ed-pro-main-top'>
											<div className='product-img'>
												{ProItem.itemImage !== '' && (
													<img
														src={ProItem.itemImage}
														alt={ProItem.itemName}
													/>
												)}
											</div>
											<div className='product-desc'>
												<div className='ed-pro-merge'>
													<h3 className='ot-title'>{ProItem.itemName}</h3>
													{ProItem.comboset.length > 0 && (
														<div className='otp-desc-comob'>
															{ProItem.comboset.map(
																(comboItem, comobIndex) => {
																	return (
																		<p key={comobIndex}>
																			<strong>
																				{
																					comboItem.comboSetname
																				}
																			</strong>
																			{comboItem
																				.productDetails
																				.length > 0 &&
																				comboItem.productDetails.map(
																					(
																						comobPro,
																						comboProIndex,
																					) => {
																						return (
																							<span
																								key={
																									comboProIndex
																								}>
																								{comboProIndex !==
																									0 &&
																									' + '}
																								{
																									comobPro.productName
																								}
																								{parseInt(
																									comobPro.quantity,
																								) >
																									0 &&
																									'X ' +
																										comobPro.quantity}
																								{parseFloat(
																									comobPro.productPrice,
																								) >
																									0 && (
																									<>
																										(+
																										{showPrice(
																											comobPro.productPrice,
																										)}

																										)
																									</>
																								)}
																							</span>
																						);
																					},
																				)}
																		</p>
																	);
																},
															)}
														</div>
													)}
												</div>
												<div className='ed-pro-price'>
													{showPrice(ProItem.itemTotalPrice)}
												</div>
											</div>
										</div>
										<div className='ed-pro-main-btm'>
											<div className='ep-lhs'>
												{/*  <a href="#">Edit Menu</a> */}
											</div>
											<div className='ep-rhs'>
												<div
													className='qty-bx'
													id={'item_' + ProItem.itemID}>
													<span
														className='qty-minus'
														onClick={this.proQtyIncDecUpdate.bind(
															this,
															ProItem.itemID,
															ProItem.itemQuantity,
															'dec',
														)}>
														<img src={minusi} />
													</span>
													<div className='input-quantity'>
														{ProItem.itemQuantity}
													</div>
													<span
														className='qty-plus'
														onClick={this.proQtyIncDecUpdate.bind(
															this,
															ProItem.itemID,
															ProItem.itemQuantity,
															'inc',
														)}>
														<img src={plusi} />
													</span>
												</div>
											</div>
										</div>
									</div>
								);
							}
						});
					}
				});
			}
		}
		this.setState({
			displayExistCartItem: displayExistCartItem,
			selectedProducttotalPrice: totalPrice,
			selectedProductName: selectedProductName,
		});
	}
	addAnother() {
		this.setState({ loadAnotherPro: true }, function () {
			showBtnLoader('add_another_item');

			this.loadProductDetail(this.state.selectedProduct);
		});
	}
	closeProduct() {
		this.setState({ openExistItem: false, openFilterSheetOrder: true }, () => {
			$('body').removeClass('bodybgdark');
		});
	}
	proQtyIncDecAction(type) {
		this.child.compoQtyAction(type);
	}
	addtoCartSelectPro() {
    console.log('addtoCartSelectPro product list');
		this.child.addToCartCombo(this.state.productDetails[0], 'done');
	}
	proQtyIncDecUpdate(itemID, itemQuantity, type) {
		var updQty = 0;
		if (type === 'inc') {
			updQty = parseInt(itemQuantity) + 1;
		} else {
			updQty = parseInt(itemQuantity) - 1;
		}
		var postObject = {
			unquieid: unquieID,
			availabilityID: deliveryId,
			locationID: cookie.load('locationID'),
			shopID: encodeValue(this.props.product.storeID),
			customerID: cookie.load('customerID'),
			itemID: itemID,
			quantity: updQty,
		};
		showLoader('item_' + itemID);
		axios.post(apiUrl + 'cart/updateCartItem', qs.stringify(postObject)).then((res) => {
			hideLoader('item_' + itemID);
			if (res.data.status === 'ok') {
				this.setState({ trigerCart: true });
			} else if (res.data.status === 'error') {
				showAlert('error', res.data.message);
			}
		});
	}

	async clearCart() {
    console.log('clearCart');
		var postObject = {
			unquieid: unquieID,
			customerID: cookie.load('customerID'),
		};
		await axios.post(apiUrl + 'cart/removeCart', qs.stringify(postObject)).then((res) => {
			this.setState({ trigerCart: true, openStoreAlert: false }, () => {
				$('body').removeClass('bodybgdark');
			});
		});
	}

	handleImageLoad = async (productId, product_thumbnail) => {
		this.setState((prevState) => ({
			imageLoadStatus: {
				...prevState.imageLoadStatus,
				[productId]: 'loaded',
			},
		}));

		const key = this.state.categorySlug || this.props.match.params.categorySlug || 'index';
		const imageUrl = product_thumbnail.replace(publicImageUrl, privateImageUrl);

		try {
			// Use the fetchAndCompressImage function to fetch, compress, and store the image
			const dataUrl = await fetchAndCompressImage(key, product_thumbnail, imageUrl);
		
			if (dataUrl) {
			  	// Save the image data URL to IndexedDB using the imported function
			  	saveToIndexedDB(key, product_thumbnail, dataUrl);
			}
		} catch (error) {
			console.error('Error in handleImageLoad:', error);
		}
	};

	handleImageError = (productId) => {
		this.setState((prevState) => ({
			imageLoadStatus: {
				...prevState.imageLoadStatus,
				[productId]: 'error',
			},
		}));
	};

	render() {
		const key = this.state.categorySlug || this.props.match.params.categorySlug || 'index';
		return (
			<div key={`product-list-${key}`} className='product-list'>
				<div
					className={this.props.storeOpen === '0' ? 'storeclosed inner-store-close' : ''}>
					<Cart sateValChange={this.sateValChange} trigerCart={this.state.trigerCart} />
					{this.props.product.productLoading === true ? (
						<ContentLoader viewBox='0 0 300 60'>
							<rect x='5' y='5' rx='0' ry='0' width='50' height='50' />
							<rect x='60' y='23' rx='0' ry='0' width='240' height='10' />
							<rect x='100' y='80' rx='0' ry='0' width='90' height='10' />
							<rect x='360' y='80' rx='0' ry='0' width='90' height='10' />
							<rect x='60' y='5' rx='0' ry='0' width='240' height='10' />
							<rect x='60' y='40' rx='0' ry='0' width='240' height='10' />
						</ContentLoader>
					) : (
						this.state.productList.length > 0 && (
							<ul>
								{this.state.productList.map((item, index) => {
									var checkingItem = this.checkingItem(item.product_id);
									var itemPrice =
										item.specialPriceApplicable === 'Yes'
											? item.product_special_price
											: item.product_price;
									const imageLoaded = this.state.imageLoadStatus[item.product_id];
									const imageLocalStorage = displayImageFromIndexedDB(
										key,
										item.product_thumbnail,
									);
                  const cart = this.state.cartdetails?.store?.[0]?.item?.find((product) => product.productID === item.product_id)
									return (
										<li
											key={index}
											className={
												item.product_slug === this.state.productSlug
													? 'active'
													: ''
											}
											id={`pro_${item.product_id}`}>
											<div style={{ height: '80px', width: '80px' }}>
												{!imageLoaded || imageLoaded === 'loaded' ? (
													<img
														style={{
															height: '100%',
															width: '100%',
															objectFit: 'cover',
															objectPosition: 'center',
														}}
														src={
															imageLocalStorage?.length > 30
																? imageLocalStorage
																: item.product_thumbnail
														}
														alt={item.product_name}
														onLoad={() => {
															this.handleImageLoad(
																item.product_id,
																item.product_thumbnail,
															);
														}}
														onError={() => {
															this.handleImageError(item.product_id);
														}}
													/>
												) : imageLoaded === 'error' ? (
													<img
														style={{
															height: '100%',
															width: '100%',
															objectFit: 'cover',
															objectPosition: 'center',
														}}
														src={foodImg}
														alt={item.product_name}
													/>
												) : (
													<ContentLoader viewBox='0 0 80 80'>
														<rect
															x='0'
															y='0'
															rx='5'
															ry='5'
															width='80'
															height='80'
														/>
													</ContentLoader>
												)}
											</div>
											<div className='product-desc'>
												<h3 className='ot-title'>
													{item.product_alias !== '' &&
													item.product_alias !== null
														? stripslashes(item.product_alias)
														: stripslashes(item.product_name)}
												</h3>
												{item.product_short_description !== '' &&
													item.product_short_description !== null &&
													Parser(item.product_short_description)}
												<div className='pro-action'>
													<div className='pro-price'>
														{showPrice(itemPrice)}
														{item.specialPriceApplicable === 'Yes' ? (
															<div className='special-price'>
																{showPrice(item.product_price)}
															</div>
														) : (
															item.product_cost !== '' &&
															parseFloat(item.product_cost) > 0 && (
																<div className='special-price'>
																	{showPrice(item.product_cost)}
																</div>
															)
														)}
													</div>

													{parseInt(checkingItem) > 0 ? (
														<div
															className='pro-qtybx'
															id={`pro_${item.product_id}`}>
															<div className='qty-bx'>
																<span
																	className='qty-minus'
																	onClick={this.proQtyAction.bind(
																		this,
																		item,
																		checkingItem,
																		'dec',
																	)}>
																	<img src={minusi} />
																</span>
																<div className='input-quantity'>
																	{checkingItem}
																</div>
																<span
																	className='qty-plus'
																	onClick={this.proQtyAction.bind(
																		this,
																		item,
																		checkingItem,
																		'inc',
																	)}>
																	<img src={plusi} />
																</span>
															</div>
														</div>
													) : (
														<div
															className='pro-btn'
															id={`pro_${item.product_id}`}>
															<a
																href={void 0}
																className='button'
																onClick={this.addtoCart.bind(
																	this,
																	item,
																	'initial',
																)}>
																Add
															</a>
														</div>
													)}
												</div>
											</div>
											{parseInt(checkingItem) > 0 && (<Notes item_id={cart.itemID} notes={cart?.itemNotes || ""} />)}
										</li>
									);
								})}
							</ul>
						)
					)}
				</div>
				<footer className='footer-main'>
					{this.state.productDetails !== '' && (
						<IonFooter collapse='fade' className='product-details-btmsheet'>
							<ProductDetail
								ref={(cd) => (this.child = cd)}
								productDetails={this.state.productDetails}
								storeID={this.props.product.storeID}
								openExistCartItem={this.state.openExistCartItem}
								incdecPro={this.state.incdecPro}
								sateValChange={this.sateValChange}
							/>
							<div className='btnfull-footer'>
								<div className='bf-desc'>
									<div className='bf-desc-lhs'>
										<p>
											<sup>Rp</sup>
											<span id='addtocartPrice'></span>
										</p>
									</div>
									<div className='qty_bx'>
										<span
											className='qty_minus'
											onClick={this.proQtyIncDecAction.bind(this, 'decr')}>
											<img src={minusi} />
										</span>
										<input
											type='text'
											value={this.state.compoinput_value}
											className='proqty_input'
											readOnly={true}
										/>
										<span
											className='qty_plus'
											onClick={this.proQtyIncDecAction.bind(this, 'incr')}>
											<img src={plusi} />
										</span>
									</div>
								</div>

								<div className='bf-desc-btn'>
									<a
										className='button compo_addcart_cls_parant'
										onClick={this.addtoCartSelectPro.bind(this)}>
										Add to cart
									</a>
								</div>
							</div>
						</IonFooter>
					)}
					{this.state.openExistItem === true && (
						<IonFooter collapse='fade'>
							<div className='product-go-back'>
								<a href={void 0} onClick={this.closeProduct.bind(this)}>
									<img src={closeicon} />
								</a>
							</div>
							<div className='edit-custm-order'>
								<div className='edit-product-name'>
									<div className='ep-lhs'>
										<h2>{this.state.selectedProductName}</h2>
									</div>
									<div className='ep-rhs'>
										<span>
											{showPrice(this.state.selectedProducttotalPrice)}
										</span>
										<em>Total Price</em>
									</div>
								</div>
								{this.state.displayExistCartItem}
							</div>
							<div className='add-another-order'>
								<a
									href={void 0}
									onClick={this.addAnother.bind(this)}
									className='button add-anr-btn height-declared'
									id='add_another_item'>
									Add Another Custom Order
								</a>
							</div>
						</IonFooter>
					)}
					{this.state.openStoreAlert === true && (
						<IonFooter collapse='fade'>
							<div className='textcenter'>
								<div className='sry-no service-no alert-switch'>
									<h2>Alert</h2>
									<p>By switching you are about to clear your cart.</p>
									<p>Do you wish to proceed ?</p>
									<a
										href={void 0}
										onClick={this.clearCart.bind(this)}
										className='button button-full'>
										Yes
									</a>
									<a
										href={void 0}
										className='button alert-switch-transprantbtn'
										onClick={() =>
											this.setState(
												{
													openStoreAlert: false,
													openFilterSheetOrder: true,
												},
												() => {
													$('body').removeClass('bodybgdark');
												},
											)
										}>
										No
									</a>
								</div>
							</div>
						</IonFooter>
					)}
					{this.state.openFilterSheetOrder === true &&
						parseInt(this.state.totalCartItem) > 0 &&
						this.state.openStoreAlert === false &&
						this.state.openExistItem === false &&
						this.state.productDetails === '' && (
							<IonFooter
								collapse='fade'
								className='edit-custm-popup product-order-now no-indicator shop-closed-footer'>
								<div className='btnfull-footer'>
									<div className='bf-desc'>
										<div className='bf-desc-lhs'>
											<p>Cart Items ({this.state.totalCartItem})</p>
										</div>
										<div className='bf-desc-rhs'>
											<span>
												{showPriceDirect(this.state.cartdetails?.subTotal)}
											</span>
										</div>
									</div>

									<div className='bf-desc-btn'>
										<Link
											to={'/cart'}
											className='button compo_addcart_cls_parant'>
											Order
										</Link>
									</div>
								</div>
							</IonFooter>
						)}
				</footer>
			</div>
		);
	}
}
const mapStateTopProps = (state) => {
	var cartdetailsArr = Array();
	if (Object.keys(state.cartdetails).length > 0) {
		if (state.cartdetails[0].status === 'ok') {
			cartdetailsArr = state.cartdetails[0].result;
		}
	}
	return {
		cartdetails: cartdetailsArr,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getStoreList: (params) => {
			dispatch({ type: GET_STORE_LIST, params });
		},
	};
};
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(ProductList));
